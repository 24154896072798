import { atom } from "jotai";

interface TopBarTheme {
	className?: string;
	background: string;
	fill: string;
	text: string;
	border: string;
	stroke: string;
	menuBackground: string;
	menuBackgroundImage: string;
}

export const defaultTheme: TopBarTheme = {
	background: "bg-black",
	fill: "fill-white",
	text: "text-white",
	border: "border-white",
	stroke: "stroke-white",
	// menuBackground: `bg-[url(${EuphoriaGradient.replace(" ", "_")})]`,
	menuBackground: `bg-black`,
	menuBackgroundImage: ``,
};
export const topBarThemeAtom = atom<TopBarTheme>(defaultTheme);
